/** @jsx jsx */
import { jsx } from "@emotion/core"
import { Link } from "gatsby"
import { Col, Row } from "react-bootstrap"
import Container from "react-bootstrap/Container"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import { urlBrokerArea } from "../../utils/siteConfig"

const SocialLink = styled.a`
  position: relative;
  height: 54px;
  width: 54px;
  border-radius: 50%;
  margin: 0 10px;
  &:before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-transition: opacity 0.4s;
    transition: opacity 0.4s;
    background: url(${require("../../images/vectors/border.svg")}) no-repeat 50%;
  }
`
const SocialDiv = styled.div`
  user-select: none;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  display: inline-block;
  vertical-align: middle;
`

const Footer = props => {
  return (
    <footer
      css={{
        backgroundColor: props.footerBg,
      }}
    >
      {props.footerHalf === true && (
        <div css={{ position: "relative" }}>
          <div
            css={theme => ({
              position: "absolute",
              top: "50%",
              width: "100%",
              height: "50%",
              background: theme.gradients.primary,
            })}
          />
          <Container>
            <div className="rounded-lg position-relative bg-secondary text-white px-4 px-md-5 px-lg-0 py-5">
              <Row className="justify-content-center">
                <Col lg={10}>
                  <Row className="text-center text-md-left">
                    <Col md={6} className="mb-3 mb-md-0">
                      <div className="h2 mb-2">
                        <small>Rejoignez plus de</small>
                        <br />
                        <span className="font-weight-bold">
                          3{"\u00A0"}000 partenaires
                        </span>
                      </div>
                      <a
                        className="text-primary"
                        href={urlBrokerArea}
                        target="_blank"
                        rel="noreferrer"
                      >
                        Déjà enregistré ? Connectez-vous
                      </a>
                    </Col>
                    <Col
                      md={6}
                      className="d-flex align-items-center justify-content-center justify-content-md-end"
                    >
                      <Link
                        className="btn btn-light btn-width font-weight-bold"
                        to="/inscription/"
                      >
                        Devenir partenaire
                      </Link>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
      )}
      <div
        className="footer-copyright"
        css={theme => ({
          background: theme.gradients.primary,
          padding: "3.4rem 0",
        })}
      >
        <Container className="text-white">
          <div className="d-flex justify-content-center mb-4">
            <SocialLink
              className="mx-2"
              href="https://www.facebook.com/SimulassurbyElois/"
              target="_blank"
              rel="noreferrer"
            >
              <SocialDiv>
                <img
                  src={require("../../images/icons/facebook.svg")}
                  width="6"
                  alt="logo facebook"
                />
              </SocialDiv>
            </SocialLink>
            <SocialLink
              className="mx-2"
              href="https://www.linkedin.com/company/elois-assurances/"
              target="_blank"
              rel="noreferrer"
            >
              <SocialDiv>
                <img
                  src={require("../../images/icons/linkedin.svg")}
                  width="12"
                  alt="logo linkedin"
                />
              </SocialDiv>
            </SocialLink>
            <SocialLink
              className="mx-2"
              href="https://twitter.com/SimulassurElois"
              target="_blank"
              rel="noreferrer"
            >
              <SocialDiv>
                <img
                  src={require("../../images/icons/twitter.svg")}
                  width="12"
                  alt="logo twitter"
                />
              </SocialDiv>
            </SocialLink>
          </div>
          <Row className="align-items-center">
            <Col md={4} className="text-center text-md-left order-3 order-md-1">
              <div className="d-flex flex-column">
                <Link to={"/mentions-legales/"}>Mentions légales</Link>
                <Link to={"/reclamation/"}>Réclamation</Link>
              </div>
            </Col>
            <Col md={4} className="text-center order-1 order-md-2 mt-3 mt-md-0">
              <img
                src={require("../../images/logos/simulassur-w.svg")}
                alt="logo simulassur blanc"
                width="188"
                height="40"
              />
            </Col>
            <Col md={4} className="order-2 order-md-3 my-3 my-md-0">
              <p
                className="text-center text-md-right mb-0"
                css={{ fontSize: "1rem" }}
              >
                © 2020 Simulassur All Right Reserved
              </p>
            </Col>
          </Row>
        </Container>
      </div>
    </footer>
  )
}

Footer.propTypes = {
  footerHalf: PropTypes.bool.isRequired,
  footerBg: PropTypes.string.isRequired,
}

export default Footer
